.icon {
    font-size: 24px;
    line-height: 10px;
}

.chaynsIcon {
    width: 28px;
    &:not(.dark) {
        filter: invert(1);
    }
}

.backgroundLeftBar{
    transition: background-color .3s ease, right .3s ease;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin-right: 10px;
    z-index: -1;
}

.iconWrapper {
    position: relative;
    height: 42px;
    min-width: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 2px;
    margin-left: 10px;

    &:not(.dark).barActive .backgroundLeftBar {
        background-color: rgba(0,0,0,0.15);
    }

    &:not(.dark) .iconTextWrapper {
        background-color: white;
    }

    &:not(.barActive):hover {
        .backgroundLeftBar {
            background-color: rgba(0,0,0,0.15);
        }

        &.dark .backgroundLeftBar {
            background-color: rgb(30, 30, 30, .3);
        }
    }

    &.showTooltips:hover {
        .iconTextWrapper {
            opacity: 1;
        }
    }
}

.barActive{
    .backgroundLeftBar {
        background-color: rgba(0,94,185, .2);
    }

    &.dark .backgroundLeftBar {
        background-color: rgb(30, 30, 30, .3);
    }
}

.badge {
    position: absolute;
    top: 0;
    left: 23px;
    font-size: 9px;
    background-color: #E71E28;
    border-radius: 20px;
    color: white;
    width: fit-content;
    text-align: center;
    min-width: 20px;
    line-height: 1.7;
    animation: fadeIn .3s .3s ease;
    opacity: 0;
    animation-fill-mode: forwards;
    z-index: 2;

    &.smallBadge {
        min-width: 17px;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.iconAbsolute {
    z-index: 1;
    font-size: 21px;
    line-height: 10px;
    color: white;
}

.chaynsIdIcon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.leftBarIconLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    color: white;
}
.iconTextWrapper {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    margin-left: 45px;
    background-color: #1e1e1e;
    border-radius: 4px;
    left: 1px;
    top: 6px;
    transition: opacity .3s;
    bottom: 6px;
    padding-right: 10px;
    padding-left: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    box-shadow: 1px 3px 8px rgba(0, 0,0,.3);
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.davidIcon {
    width: 26px;

    &.dark {
        filter: invert(1);
    }
}


.item {
    display: flex;
    align-items: center;
}
